import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import "../../assets/style/results-tv.css";

const ResultsHeader = ({
  testDriver,
  page,
  typeRaces,
  hasTeams,
  laps,
  showSectors,
}: {
  testDriver: { [key: string]: any };
  page: string;
  typeRaces?: number;
  hasTeams?: number;
  laps?: { laps: number; valid_laps: number | null };
  showSectors?: boolean;
}) => {
  const { t } = useTranslation();
  let { grandprix } = useParams();
  let testLap = null;

  try {
    testLap = testDriver.laps[0];
  } catch (err) {
    //Case no LOGs
  }
  const getBalanceText = () => {
    const split = t("GainedLost").split("/");
    if (split.length > 0) {
      return split[0].charAt(0) + "/" + split[1].charAt(0);
    }
    return "G/L";
  };

  const setHeatsHeaderJSX = () => {
    const cols = [];
    if (laps != null) {
      for (let i = 0; i < laps.laps; i++) {
        cols.push(
          <div className="gp-heats">
            <label>
              {t("Heat")} {i + 1}
            </label>
          </div>
        );
      }
    }
    return <>{cols}</>;
  };
  return (
    <div className="gp-session-header flex">
      {page === "Race" && testLap != null && typeRaces !== 1 && (
        <div className="gp-balance" title={t("GainedLost")}>
          <label>{getBalanceText()}</label>
        </div>
      )}
      <div className="gp-pos" title={t("Position")}>
        <label>#</label>
      </div>
      <div className="gp-driver">
        <label>{t("Driver")}</label>
      </div>
      {hasTeams === 1 && (
        <div className="gp-team">
          <label>{t("Team")}</label>
        </div>
      )}
      {testDriver.class != null && (
        <div className="gp-sector">
          <label>{t("Class")}</label>
        </div>
      )}
      {(page === "Qualifying" || page === "FastestLaps") && (
        <div className="gp-hotlap">
          <label>{t("HotLap")}</label>
        </div>
      )}
      {page !== "Leaders" && (
        <div className="gp-gap">
          <label>{t("Gap")}</label>
        </div>
      )}
      {page === "Race" && testLap != null && (
        <div className="gp-time">
          <label>{t("Time")}</label>
        </div>
      )}
      {page === "Race" &&
        typeRaces === 1 &&
        testDriver.laps.length > 1 &&
        setHeatsHeaderJSX()}
      {(page === "FastestLaps" || page === "Qualifying") && showSectors ? (
        <>
          <div className="gp-sector">
            <label>{t("Sector")} 1</label>
          </div>
          <div className="gp-sector">
            <label>{t("Sector")} 2</label>
          </div>
          <div className="gp-sector">
            <label>{t("Sector")} 3</label>
          </div>
        </>
      ) : (
        <></>
      )}
      {page === "Qualifying" &&
        testLap != null &&
        testLap.hasOwnProperty("compounds") &&
        testLap.compounds.front !== null && (
          <div className="gp-tyre" title={t("Tyres")}>
            <label>{t("Tyres").charAt(0)}</label>
          </div>
        )}
      {((testLap != null && page === "Race") || page === "Leaders") && (
        <div className="gp-laps">
          <label>{t("Laps")}</label>
        </div>
      )}
      {page === "Race" && typeRaces !== 1 && (
        <>
          {testLap != null && (
            <>
              <div className="gp-pitstops">
                <label>{t("Stops")}</label>
              </div>
              <div className="gp-laps-pitstops">
                <label>{t("PitStopLaps")}</label>
              </div>
              {testLap.hasOwnProperty("compounds") &&
                testLap.compounds.front !== null && (
                  <div className="gp-tyres">
                    <label>{t("Tyres")}</label>
                  </div>
                )}
            </>
          )}
        </>
      )}
      {testDriver != null &&
        testDriver.points != null &&
        testDriver.points > 0 && (
          <div className="gp-points">
            <label>{t("Points")}</label>
          </div>
        )}
      {testLap != null && <div className="gp-detail"></div>}
    </div>
  );
};

export default ResultsHeader;
