import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  getColor,
  teamLogo,
  teamBackground,
  getStandingsPositionStyle,
} from "../../utils/Standings";
import Legend from "./Legend";
import StandingsResult from "./StandingsResult";
import THRaces from "./THRaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";

const TeamStanding = ({ standingsData }: { standingsData: StandingsData }) => {
  const { t } = useTranslation();

  const getTeamNameLink = (team: TeamStanding) => {
    //external championship
    if (team.main_color === null) {
      return (
        <label
          style={{
            color: getColor(team.team_id.toString(), team.main_color),
          }}
        >
          {team.team_name}
        </label>
      );
    }
    return (
      <Link
        className="full-white-driver"
        to={`${t("routes:Team")}/${encodeURIComponent(team.team_name)}`}
        style={{
          color: getColor(team.team_id.toString(), team.main_color),
        }}
      >
        {team.team_name}
      </Link>
    );
  };

  const teamDriversRow = (
    total: number,
    team_id: number | string,
    champ: string
  ) => {
    let rows: JSX.Element[] = [];
    let columns: JSX.Element[] = [];
    for (let i = 1; i < total; i++) {
      columns = [];
      standingsData.results.forEach((result) => {
        result.Races.forEach((race, index1) => {
          const driver_result = race.Results.filter((driver_) => {
            if (driver_.team_number != null)
              return (
                driver_.team_id === team_id && driver_.team_number === i + 1
              );
            //Case external (we get the total drivers from the team)
            //We need to do like that cause can be drivers with more than 1 team, and on the standings there will be only 1 assignment
            const drivers: any[] = [];
            for (let standing_driver of standingsData.driver_standings) {
              for (let p of standingsData.results) {
                for (let q of p.Races) {
                  for (let k of q.Results) {
                    if (
                      k.username === standing_driver.username &&
                      k.team_id === team_id
                    )
                      if (!drivers.includes(k.username))
                        drivers.push(k.username);
                  }
                }
              }
            }
            return (
              driver_.team_id === team_id && driver_.username === drivers[i]
            );
          });
          const driver_result_Q = result.hasOwnProperty("Qualy")
            ? result.Qualy.filter(
                (driver_) =>
                  driver_result[0] != null &&
                  driver_.name === driver_result[0].username
              )
            : null;
          columns.push(
            <StandingsResult
              key={`${race.id}-${index1}`}
              driver_result={driver_result}
              poleman={result.Poleman}
              fastest={race.hot_lap_usernames}
              most_leader={race.most_leader}
              leaders={race.leaders}
              qualy={driver_result_Q}
              isDrivers={false}
            />
          );
        });
      });
      rows.push(
        <tr key={"champ" + champ} className={"champ" + champ}>
          {columns}
        </tr>
      );
    }
    return <>{rows}</>;
  };

  return (
    <div className="frame-turqoise">
      <h3 className="text-center frame-title">
        {t("TeamStandings").toUpperCase()}
      </h3>
      <div className="flex-container">
        <div className="flex">
          <table
            className="standings-table"
            key={JSON.stringify(
              standingsData.team_standings.map((t) => t.team_id)
            )}
          >
            <thead>
              <tr>
                <th className="text-center">#</th>
                <th className="text-center">↕</th>
                <th className="text-center">{t("Team")}</th>
                <THRaces results={standingsData.results} />
                <th>{t("Points")}</th>
              </tr>
            </thead>
            <tbody>
              {standingsData.team_standings.length === 0 ? (
                <tr className="no-standing-drivers">
                  <td colSpan={standingsData.results.length + 3}>
                    {t("NoStandingData")}
                  </td>
                </tr>
              ) : (
                standingsData.team_standings.map((team, index) => {
                  const class_ = team.position === 1 &&
                    team.catalog != null &&
                    team.class_color != null &&
                    team.class != null && (
                      <tr key={"class-" + index}>
                        <td
                          colSpan={100}
                          style={{ background: team.class_color }}
                          className="class-tr-separator"
                        >
                          {team.class.toUpperCase()}
                        </td>
                      </tr>
                    );
                  const teamsInPosition = standingsData.team_standings.filter(
                    (team_) =>
                      team_.position === team.position &&
                      team_.class === team.class
                  );
                  const rowsPan = teamsInPosition.reduce(
                    (total, obj) => total + obj.count_number_team,
                    0
                  );
                  const index_team = teamsInPosition.indexOf(team);
                  return (
                    <React.Fragment key={`${index}`}>
                      {index_team === 0 && class_}
                      <tr>
                        {index_team === 0 && (
                          <td rowSpan={rowsPan} className="standings-pos">
                            {team.position}
                          </td>
                        )}
                        <td
                          rowSpan={team.count_number_team}
                          className={
                            team.balance > 0
                              ? "bal-gain"
                              : team.balance < 0
                              ? "bal-loss"
                              : "bal-def"
                          }
                        >
                          <FontAwesomeIcon
                            icon={
                              team.balance > 0
                                ? faAngleUp
                                : team.balance < 0
                                ? faAngleDown
                                : faMinus
                            }
                          />
                          {team.balance == 0 ? "" : Math.abs(team.balance)}
                        </td>
                        <td
                          className="no-td-padding standings-teamdriver"
                          rowSpan={team.count_number_team}
                          style={{
                            background: teamBackground(
                              team.team_id.toString(),
                              team.main_color
                            ),
                          }}
                        >
                          <div
                            className="teamdriver-results-container"
                            style={{
                              background: teamBackground(
                                team.team_id.toString(),
                                team.main_color
                              ),
                            }}
                          >
                            <div className="logo-results-container flex-center">
                              {teamLogo(
                                team.team_id.toString(),
                                team.logo,
                                team.team_name
                              )}
                            </div>
                            {getTeamNameLink(team)}
                          </div>
                        </td>
                        {standingsData.results.map((result, index) => {
                          return (
                            <React.Fragment key={index}>
                              {result.Races.map((race, index) => {
                                const driver_result = race.Results.filter(
                                  (driver_) => {
                                    if (driver_.team_number != null)
                                      return (
                                        driver_.team_id === team.team_id &&
                                        driver_.team_number === 1
                                      );
                                    //Case no team_championship
                                    const team_drivers =
                                      standingsData.driver_standings.filter(
                                        (driver) =>
                                          driver.teams_array.includes(
                                            team.team_name
                                          )
                                      );
                                    return (
                                      driver_.team_id === team.team_id &&
                                      team_drivers[0] != null &&
                                      driver_.username ===
                                        team_drivers[0].username
                                    );
                                  }
                                );
                                const driver_result_Q = result.hasOwnProperty(
                                  "Qualy"
                                )
                                  ? result.Qualy.filter(
                                      (driver_) =>
                                        driver_result[0] != null &&
                                        driver_.name ===
                                          driver_result[0].username
                                    )
                                  : null;
                                return (
                                  <StandingsResult
                                    key={index}
                                    driver_result={driver_result}
                                    poleman={result.Poleman}
                                    fastest={race.hot_lap_usernames}
                                    most_leader={race.most_leader}
                                    leaders={race.leaders}
                                    qualy={driver_result_Q}
                                    isDrivers={false}
                                  />
                                );
                              })}
                            </React.Fragment>
                          );
                        })}
                        {index_team === 0 && (
                          <td
                            rowSpan={rowsPan}
                            className={`standings-tab-points ${
                              typeof team.position === "number" &&
                              getStandingsPositionStyle(
                                team.is_dsq ? "DSQ" : team.position
                              )
                            }`}
                          >
                            <b>{team.points}</b>
                          </td>
                        )}
                      </tr>
                      {team.count_number_team > 1 &&
                        teamDriversRow(
                          team.count_number_team,
                          team.team_id,
                          standingsData.champ_data.name.replace(/ /g, "")
                        )}
                    </React.Fragment>
                  );
                })
              )}
            </tbody>
            <tfoot>
              <tr>
                <th className="text-center">#</th>
                <th className="text-center">↕</th>
                <th className="text-center">{t("Team")}</th>
                <THRaces results={standingsData.results} />
                <th>{t("Points")}</th>
              </tr>
            </tfoot>
          </table>
          <Legend
            champ_data={standingsData.champ_data}
            gps_with_qualy_points={standingsData.gps_with_qualy_points}
            one_point_qualy={standingsData.one_point_qualy}
          />
        </div>
      </div>
    </div>
  );
};

export default TeamStanding;
